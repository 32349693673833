import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../constants/objects.constants';
import { estabelecimentoService } from '../../services/estabelecimento.service';
import { userService } from '../../services/user.service';
import CooperadoForm from '../cooperados/CooperadoForm';
import TopoTitleComponente from '../home/TopoTitleComponente';
import UsuarioForm from './UsuarioForm';
import UsuarioTopoView from './UsuarioTopoView';
import Datatable from '../../sascComponents/datagrid/Datatable';
import LinhaAcaoAtivarInativarEstabelecimentoUsuario from './LinhaAcaoAtivarInativarEstabelecimentoUsuario';
import { estabelecimentoUsuarioService } from '../../services/estabelecimentoUsuario.service';
import { convenioUsuarioService } from '../../services/convenioUsuario.service';
import { Modal, ModalBody } from 'reactstrap';
import SelectInput from '../../components/Inputs/SelectInput';
import InputViewEdit from '../../components/Inputs/InputViewEdit';
import BandeirasUsuarioForm from './BandeirasUsuarioForm';

const fields = [
  {
    "path": "ConvenioUsuario.convenio",
    "fieldName": "sigla",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": null,
    "url": "/atendimento/getConveniosPorAtendimento",
    "fieldDescription": "Convênio",
    "type": "SELECT"
  },
  {
    "path": "ConvenioUsuario.tiposGuiaConvenioUsuario",
    "fieldName": "nome",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "/atendimento/getTiposGuia",
    "fieldDescription": "Tipo de guia não atendido pelo convênio",
    "type": "SELECT"
  },
  {
    "path": "ConvenioUsuario",
    "fieldName": "vinculo",
    "onlyNumber": false,
    "searchEmpty": false,
    "emptyTextSearch": "",
    "url": "/convenioUsuario/getFilterVinculo",
    "fieldDescription": "Convênio vinculado ao cooperado",
    "type": "SELECT"
  }
];

class UsuarioView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: { status: true },
      convenioUsuarioEditTiposGuia: [],
      convenioUsuarioEdit: {},
      showModalConvenioUsuario: false,
      userPerfis: []
    };
  }


  componentDidMount() {
    this.loadUser();
  }

  getServiceDoSave = () => {
    let entity =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.entity;
    if (
      entity &&
        entity.perfilAtual!=null&&entity.perfilAtual.perfil!=null&&
        entity.perfilAtual.perfil.tipoPerfil === objectsConstants.CONSULTORIO

    ) {
      return estabelecimentoService;
    }
    return userService;
  };
  getIdDoSave = () => {
    let entity =
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.entity;
    if (
        entity &&
        entity.perfilAtual!=null&&entity.perfilAtual.perfil!=null&&
        entity.perfilAtual.perfil.tipoPerfil === objectsConstants.CONSULTORIO
    ) {
      return entity.perfilAtual.extensao;
    }
    return this.props.match.params.id;
  };

  loadUser = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      let service = userService;
      let id = this.props.match.params.id;
      service.doGet(id).then(
        response => {
          let user = response.data.data;
          this.setState({ entity: user, userPerfis : user.usuarioPerfis }, () => {
            this.props.loading(false);
          });
        },
        error => {
          console.error(error);
          this.props.loading(false);
        }
      );
    }
  };

  isAtivo = values => {
    return values.status && values.status.name === 'ATIVO' ? true : false;
  };

  disableEnabledUser = () => {
    let values = this.state.entity;
    this.props.loading(true);
    let functionStatus = this.isAtivo(values)
      ? userService.inativarUsuario
      : userService.ativarUsuario;
    functionStatus([values.id])
      .then(response => {
        this.loadUser();
        this.props.success({ message: 'Alterado com sucesso.' });
        this.props.loading(false);
      })
      .catch(() => {
        this.props.success('Não foi possível inativar o usuário.');
        this.props.loading(false);
      });
  };

  initFilterEstabelecimento = () => {
    return [{
      condition : { description: '=' },
      fieldName: "id",
      onlyNumber: true,
      path: "EstabelecimentoCooperado.cooperado",
      value: this.state.entity.id
    }]
  }

  initFilterConvenio = () => {
    return [{
      condition : { description: '=' },
      fieldName: "id",
      onlyNumber: true,
      path: "ConvenioUsuario.usuario",
      value: this.state.entity.id
    }]
  }

  hasPerfilCooperado = () => {
    return this.state.userPerfis.some(perfil => perfil.perfil.tipoPerfil === objectsConstants.COOPERADO);
  }

  handleConveniosRowClick = (fieldName, rowData) => {
    if (fieldName === 'nome') {
      this.setState({ convenioUsuarioEdit : rowData.convenio})
      this.setState({ convenioUsuarioEditTiposGuia: rowData.tiposGuiaConvenioUsuario }, () => {
        this.toogleModalConvenioUsuario()
      });
    }
  };

  toogleModalConvenioUsuario = () => {
    this.setState({ showModalConvenioUsuario: !this.state.showModalConvenioUsuario })
  }

  vincularConvenioUsuario = () => {
    this.props.loading(true);
    const conUsr = {
      convenio: this.state.convenioUsuarioEdit,
      tiposGuiaConvenioUsuario: this.state.convenioUsuarioEditTiposGuia,
      usuario: this.state.entity
    }
    convenioUsuarioService.vincularUsuario(conUsr).then(response => {
      this.props.success({ message: 'Alterado com sucesso.' });
      this.props.loading(false);
      this.dataTable.initData();
    })
  }

  render() {
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-com-topo">
          {entity.id && (
            <React.Fragment>
              <TopoTitleComponente
                mainTitle={`Dados do usuário`}
                canBack={true}
                backUrl={'/usuario'}
              />
              <UsuarioTopoView
                values={entity}
                viewMode={true}
                disableEnabledUser={this.disableEnabledUser}
                qtdeAtendimento={entity.qtdeAtendimento}
              ></UsuarioTopoView>

              <FormByProfile
                values={entity}
                viewMode={true}
                errors={[]}
              ></FormByProfile>

              <hr className="text-center"></hr>

              <div className="background-lines">
                <div className="bg-riscos"></div>
              </div>

              <Datatable
                showCheckboxColumn={true}
                onRowClick={this.handleRowClick}
                idRow={"id"}
                keyTagItem={"nome"}
                classHeader={[
                  "headerCenter",
                  "headerLeft",
                  "headerCenter",
                  "headerCenter",
                  "headerCenter",
                  "headerCenter",
                  "headerCenter",
                  "headerCenter"

                ]}
                classColumn={[
                  "colunaFoto",
                  "colunaGra",
                  "colunaMed",
                  "colunaMed",
                  "colunaPeq",
                  "colunaMed",
                  "colunaMed",
                  "colunaMed"
                ]}
                service={estabelecimentoUsuarioService}
                name={"Consultório(s)"}
                showAllColumnsFilter={false}
                ActionLine={LinhaAcaoAtivarInativarEstabelecimentoUsuario}
                initFilter={this.initFilterEstabelecimento()}
              />

              {this.hasPerfilCooperado() && <Datatable
                showCheckboxColumn={true}
                onRowClick={this.handleConveniosRowClick}
                idRow={"id"}
                keyTagItem={"sigla"}
                listFields={fields}
                classHeader={[
                  "headerCenter",
                  "headerCenter",
                  "headerCenter"

                ]}
                classColumn={[
                  "colunaMed",
                  "colunaMed",
                  "colunaMed"
                ]}
                service={convenioUsuarioService}
                name={"Convênio(s)"}
                showAllColumnsFilter={false}
                initFilter={this.initFilterConvenio()}
                wrappedComponentRef={c => (this.dataTable = c)}
              />}

            </React.Fragment>
          )}
          <Modal
            isOpen={this.state.showModalConvenioUsuario}
            toggle={this.toogleModalConvenioUsuario}
            backdrop="static"
            modalClassName=""
            centered={true}
            size={"lg"}
          >
            <ModalBody>
              <div className="confirm-ui css-alert-sasc"><h2>Vincular tipo de guia</h2><p><b></b></p>
                <div className="flex-grow m-auto justify-content-center">
                  <InputViewEdit
                    label="Tipo de guia não atendido pelo convênio"
                    component={SelectInput}
                    isMulti={true}
                    placeholder={"Tipos de guia"}
                    options={objectsConstants.TIPOS_GUIA_CONVENIO_USUARIO}
                    name={`tipoGuia`}
                    returnFullObject={true}
                    closeMenuOnSelect={false}
                    value={this.state.convenioUsuarioEditTiposGuia}
                    labelKey={"nome"}
                    valueKey={"id"}
                    onChange={(name, value) => {
                      this.setState({ convenioUsuarioEditTiposGuia: value })
                    }}
                  />
                </div>
                <button className="btn btn-secondary white mt-3 mb-4 mx-2" onClick={() => { this.toogleModalConvenioUsuario() }}>Cancelar</button>
                <button className="btn btn-primary white mt-3 mb-4 mx-2" onClick={() => {
                  this.toogleModalConvenioUsuario()
                  this.vincularConvenioUsuario()
                }}>Vincular
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

const FormByProfile = React.memo(({ values, ...others }) => {

  if(values.usuarioPerfis.length > 0) {
    const verifyCooperado = values.usuarioPerfis.some(perfil => perfil.perfil.tipoPerfil === objectsConstants.COOPERADO);
    if (verifyCooperado) {
      return <React.Fragment>
        <CooperadoForm
        values={values}
        viewMode={true}
        createMode={false}
        canEdit={false}
        readOnly
        errors={[]}
        {...others}
      ></CooperadoForm>
      <BandeirasUsuarioForm values={values} {...others}></BandeirasUsuarioForm>
      </React.Fragment>
      
    }

    return <UsuarioForm values={values} {...others} />;
  }
});

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(withRouter(UsuarioView));
