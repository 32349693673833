//@flow
import * as React from 'react';
import SelectInput from './SelectInput';
import _ from 'lodash';

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean,
  service?: (inputValue: string) => Promise<any>, // Ajustado para aceitar string
  defaultSelected?: boolean,
  sortKey?: string,
  parent?: any,
  formatOptionLabel?: any,
  onBlur?: any,
  alertMensagem?: any,
  components?: any,
  helpText?: any,
  erroMensagem?: any,
  placeholder?: string,
  className?: string
};

type State = {
  options: Array<any>,
  defaultValue: any
};

export default class FormSelectInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: [],
      defaultValue: null
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.parent !== this.props.parent) {
      this.fetchData(this.props.parent);
    }
  }

  componentDidMount() {
    this.fetchData(this.props.parent);
  }

  sortByKey(data: Array<any>, key: string) {
    return data.sort((a, b) => a[key] > b[key] ? 1 : -1);
  }

  fetchData(inputValue: string) {
    const { service } = this.props;

    if (typeof service === 'function') {
      const fetch = service(inputValue);
      if (fetch) {
        fetch
          .then(data => {
            let options = data.data || [];
            let defaultValue;

            if (this.props.defaultSelected && options.length) {
              defaultValue = options[0];
            }

            if (this.props.sortKey) {
              options = this.sortByKey(options, this.props.sortKey);
            }

            this.setState({ options, defaultValue });
          })
          .catch(error => {
            console.error('Erro ao montar o combo', error);
            this.setState({ options: [], defaultValue: null });
          });
      }
    } else {
      console.error('O service não é uma função');
    }
  }

  handleInputChange = (inputValue: string) => {
    this.fetchData(inputValue);
  }

  render() {
    const { ...otherProps } = this.props;
    return (
      <SelectInput
        id={this.props.id}
        label={this.props.label}
        valueKey={this.props.valueKey || 'id'}
        labelKey={this.props.labelKey || 'nome'}
        name={this.props.name}
        value={this.props.value}
        options={this.state.options}
        onChange={this.props.onChange}
        multi={false}
        returnFullObject={this.props.returnFullObject}
        messageValidate={this.props.messageValidate}
        disabled={this.props.disabled}
        size={this.props.size}
        style={this.props.style}
        required={this.props.required}
        helpText={this.props.helpText}
        erroMensagem={this.props.erroMensagem}
        className={this.props.className}
        placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
        defaultValue={this.state.defaultValue}
        components={this.props.components}
        formatOptionLabel={this.props.formatOptionLabel}
        onBlur={this.props.onBlur}
        alertMensagem={this.props.alertMensagem}
        onInputChange={this.handleInputChange} // Adiciona o manipulador de alteração
        {...otherProps}
      />
    );
  }
}
