//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
export const SubStatusService = {
  findSubStatusByCooperativa,
  findSubStatusByCooperativaFilter
};

function findSubStatusByCooperativa() {
  return defaultService.doGet(`${urlsConstants.SUB_STATUS}porCooperativa`);
}

function findSubStatusByCooperativaFilter() {
  return defaultService.doGet(`${urlsConstants.SUB_STATUS}porCooperativaFilter`);
}


